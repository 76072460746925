import React, { useState } from "react";
import EditUser from "../components/EditUser";
import Footer from "../components/Footer";
import Header from "../components/Header";
import useUserProfile from "../customhook/useUserProfile";

const Profile = () => {
    const [editing, setEditing] = useState(false);
    const { user } = useUserProfile();
    console.log(user)

    return (
        <>
            <Header />
            {editing ? (
                <EditUser setEditing={setEditing} />
            ) : (
                <section className="profile-infos">
                    <h1 className="profile-infos__title">Informations personnelles</h1>
                    <div className="profile-infos__wrapper">
                        <h2>Identité</h2>
                        <ul>
                            <li className="profile-infos__wrapper--item">
                                <span>Nom :</span>
                                {`  ${user?.lastName}`}
                            </li>
                            <li className="profile-infos__wrapper--item">
                                <span>Prénom :</span>
                                {" " + user?.firstName}
                            </li>
                        </ul>
                    </div>
                    <div className="profile-infos__wrapper">
                        <h2>Adresse et contact</h2>
                        <ul>
                            <li className="profile-infos__wrapper--item">
                                <span>Email :</span>
                                {"  " + user?.email}
                            </li>
                            <li className="profile-infos__wrapper--item">
                                <span>Téléphone :</span>
                                {"  " + user?.tel}
                            </li>
                            <li className="profile-infos__wrapper--item">
                                <span>Adresse :</span>
                                {"  " + user?.address}
                            </li>
                        </ul>
                    </div>
                    <div className="profile-infos__edit">
                        <button
                            onClick={() => {
                                setEditing(true);
                            }}
                        >
                            Modifier
                        </button>
                    </div>
                </section>
            )}

            <Footer />
        </>
    );
};

export default Profile;
