import React from 'react';

const LabelInput = ({ name, setValues, values, currentValue }) => {
    return (
        <div className='profile-edit__inputwrapper'>
            <label htmlFor={name}></label>
            <input type="text"
                value={currentValue}
                onChange={(e) => { setValues({ ...values, [e.target.id]: e.target.value }) }}
                id={name}
                autoComplete='off'
                autoCorrect='off'
            />
        </div>

    );
};

export default LabelInput;