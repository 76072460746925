import React from 'react';
import { BsTelephoneFill } from "react-icons/bs"
const Anouncement = () => {
    return (
        <p className='infos'>
            <BsTelephoneFill className='infos__icontel' />
            <strong className='infos__number'>03 84 44 31 69</strong>
        </p>
    );
};

export default Anouncement;