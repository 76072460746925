import React from 'react';
import { Link } from 'react-router-dom';
import Banner from './Banner';
import Categories from './Categories';
import ContainerProducts from './ContainerProducts';
import Description from './Description';

const HomeContent = () => {

    return (
        <>
            <Banner title="Boucherie - Charcuterie - Traiteur" />
            <ContainerProducts />
            <div className='all-products-link'>
                <Link to="/all-products" className='all-products-link__item'>Voir plus</Link>
            </div>
            <Categories />
            <Description />
        </>
    );
};

export default HomeContent;