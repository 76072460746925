import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from './components/PrivateRoutes';
import About from './pages/About';
import Admin from './pages/admin-pages/Admin';
import AdminCollection from './pages/admin-pages/AdminCollection';
import AdminCustommers from './pages/admin-pages/AdminCustommers';

import AdminOrders from './pages/admin-pages/AdminOrders';
import AdminProducts from './pages/admin-pages/AdminProducts';
import AdminUsers from './pages/admin-pages/AdminUsers';
import Dashbord from './pages/admin-pages/Dashbord';
import AllProducts from './pages/AllProducts';

import CartShop from './pages/CartShop';
import Contact from './pages/Contact';

import Home from './pages/Home';
import Login from './pages/Login';
import Orders from './pages/Orders';
import Privacy from './pages/Privacy';
import Product from './pages/Product';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Sale from './pages/Sale';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/:id' element={<Product />} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/cart' element={<CartShop />} />
      <Route path='/about' element={<About />} />
      <Route path='/sale' element={<Sale />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/all-products' element={<AllProducts />} />

      {/* private routes */}
      <Route element={<PrivateRoutes />}>
        <Route path='/profile' element={<Profile />} />
        <Route path='/orders' element={<Orders />} />
      </Route>

      {/* Admin pages route */}

      <Route element={<Admin />} >
        <Route path='/admin/dashbord' element={<Dashbord />} />
        <Route path='/admin/collection' element={<AdminCollection />} />
        <Route path='/admin/products' element={<AdminProducts />} />
        <Route path='/admin/orders' element={<AdminOrders />} />
        <Route path='/admin/customers' element={<AdminCustommers />} />
        <Route path='/admin/admins' element={<AdminUsers />} />
      </Route>
    </Routes>
  );
};

export default App;