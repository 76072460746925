import React from 'react';

const AdminUsers = () => {
    return (
        <div className='admin-admins'>
            les admins
        </div>
    );
};

export default AdminUsers;