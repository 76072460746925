import React from 'react';
import CartList from '../components/CartList';
import Header from '../components/Header';

const CartShop = () => {
    return (
        <>
            <Header />
            <CartList />
        </>
    );
};

export default CartShop;