import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from '../../components/SideBar';

const Admin = () => {
    return (
        <div className='admin'>
            <SideBar />
            <Outlet />
        </div>
    );
};

export default Admin;