import React from 'react';
import Banner from '../components/Banner';
import ContainerProducts from '../components/ContainerProducts';
import Footer from '../components/Footer';
import Header from '../components/Header';

const AllProducts = () => {
    return (
        <>
            <Header />
            <Banner title="Tous les produits" />
            <ContainerProducts />
            <Footer />
        </>
    );
};

export default AllProducts;