import { useEffect } from 'react';


const Categories = () => {



    useEffect(() => {
        const ratio = 0.1

        let options = {
            root: null,
            rootMargin: "0px",
            threshold: ratio
        };

        let target = document.querySelectorAll('div[class^="categories__card-"]');

        const handleIntersect = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > ratio) {
                    entry.target.classList.add('active')
                    observer.unobserve(entry.target)
                }
            });
        }

        let observer = new IntersectionObserver(handleIntersect, options)

        window.onscroll = () => {
            target.forEach((item) => { observer.observe(item) })
        }

        return () => {
            observer.disconnect()
        }
    }, [])

    const categories = [
        {
            title: "boucherie",
            image: require('../assets/images/boucherie.jpg')
        },
        {
            title: "traiteur",
            image: require('../assets/images/traiteur.jpg')
        },
        {
            title: "charcuterie",
            image: require('../assets/images/charcuterie.jpg')
        }
    ]
    return (
        <section className='categories'>
            {categories.map((category, index) => (
                <div className={`categories__card-${category?.title}`} key={category.title}>
                    <img src={category.image} alt={category.title} />
                    <h2 className={`categories__card-${category?.title}--title`}>{category.title}</h2>
                </div>
            ))}
        </section>
    );
};

export default Categories;