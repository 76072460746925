import axios from "axios";

const instance = axios.create({
    baseURL: "/api",
    headers: {
        "Content-Type": "Application/json",
    },
    withCredentials: true,
});

export const register = (postData) => {
    const response = instance.post("/register", postData);
    return response;
};

export const login = (postData) => {
    const response = instance.post("/login", postData);
    return response;
};

export const axiosPrivate = axios.create({
    baseURL: "/api",
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

export const createProduct = (postData) => {
    const response = instance.post("/products", postData);
    return response;
};

export const updatProduct = (id, postData) => {
    const response = instance.patch(`/products/${id}`, postData);
    return response
}

export const deleteProduct = (id) => {
    const response = instance.delete(`/products/${id}`)
    return response
}
