import React, { useState } from 'react';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import useGetAllProducts from '../customhook/useGetAllProducts';

import Card from './Card';

const ContainerProducts = () => {

    const { products } = useGetAllProducts()

    const [currentPage, setCurrentPage] = useState(1)
    const [selectRadio, setSelectedRadio] = useState("")
    const { pathname } = useLocation()

    let limit = pathname === "/" ? 8 : 12

    const lastElementPerPage = currentPage * limit
    const firstElementPerPage = lastElementPerPage - limit

    const filteredProducts = products?.filter((product) => (product?.categorie?.includes(selectRadio)))
        ?.slice(firstElementPerPage, lastElementPerPage)


    const productsLength = selectRadio === "" ? products?.length : filteredProducts?.length




    const nextPage = () => {
        if (lastElementPerPage < productsLength) setCurrentPage(prev => prev + 1)
    }
    const prev = () => {
        if (firstElementPerPage > currentPage) setCurrentPage(prev => prev - 1)
    }

    const pageNumbers = []

    for (let i = 0; i < Math.ceil(productsLength / limit); i++) {
        pageNumbers.push(i + 1)
    }


    const categories = ["Boucherie", "Traiteur", "Charcuterie"]

    return (
        <>
            {
                pathname === "/all-products" && (
                    <div className='filter'>
                        <h2 className='filter__title'>Filtrer par catégories : </h2>
                        <ul className='filter__inputwrapper'>
                            {
                                categories.map((category) => (
                                    <li key={category} className="filter__inputwrapper--item">
                                        <input type="radio"
                                            name="category"
                                            id={category}
                                            onChange={(e) => { setSelectedRadio(e.target.id); setCurrentPage(1) }}
                                            checked={category === selectRadio}
                                            style={{ backgroundColor: category }}
                                        />
                                        <label className={category === selectRadio ? "active" : ""} htmlFor={category}>{category}</label>
                                    </li>
                                ))
                            }
                        </ul>
                        {selectRadio && (<button className='filter__button' onClick={() => setSelectedRadio("")}>Annuler le filtre</button>)}
                    </div>
                )
            }

            <section className='products'>
                {
                    products?.filter((product) => (product?.categorie?.includes(selectRadio)))
                        ?.slice(firstElementPerPage, lastElementPerPage)
                        ?.map((product) => (
                            <Card key={product._id} product={product} />
                        ))
                }
            </section>
            {limit === 12 ? (
                <aside className='pagination'>
                    <div className='pagination__wrapper'>
                        <BsArrowLeftCircle className='pagination__wrapper--arrow' onClick={prev} />
                        <ul className='pagination__wrapper--numbers'>
                            {pageNumbers.map((pageNumber) => (
                                <li className='item' key={pageNumber}
                                    style={{
                                        backgroundColor: pageNumber === currentPage ? "#a90402" : "",
                                        color: pageNumber === currentPage ? "#fff" : ""
                                    }}>
                                    {pageNumber}
                                </li>
                            ))}
                        </ul>
                        <BsArrowRightCircle className='pagination__wrapper--arrow' onClick={nextPage} />
                    </div>
                </aside>
            ) : (null)}

        </>
    );
};

export default ContainerProducts;