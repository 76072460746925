import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useUserProfile from '../customhook/useUserProfile';

const PrivateRoutes = () => {
    const { user, isLoading } = useUserProfile()
    // check the result of this boolean betwen user and isError


    return (
        user || isLoading ? <Outlet /> : <Navigate to="/login" />
    );
};

export default PrivateRoutes;