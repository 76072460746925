import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
const Select = ({ selectedItem, setSelectedItem, action, prevValue }) => {
    const [isOpen, setIsOpen] = useState(false)
    const categories = ["Boucherie", "Traiteur", "Charcuterie"]
    const isInclude = categories.includes(selectedItem)

    return (
        <div className="add-product__form--inputgroup" onClick={() => setIsOpen(!isOpen)}>
            <label htmlFor="categories">Catégories</label>

            <input
                type="button"
                value={action ? selectedItem : prevValue !== selectedItem ? selectedItem : prevValue} id='categories'
                className='select'
                style={{
                    color: isInclude ? "#00152b" : "#9b9292",
                    borderBottom: isOpen ? "0" : "",
                    borderBottomLeftRadius: isOpen ? "0" : "",
                    borderBottomRightRadius: isOpen ? "0" : ""
                }}
            />
            <BsChevronDown
                className='select__icon'
                style={{ transform: isOpen ? "rotate(180deg)" : "", transition: "transform 0.2s ease" }}
            />

            {
                isOpen ?
                    (
                        <ul className='select-content' style={{ transformOrigin: isOpen ? "top" : "" }}>
                            {
                                categories.map((categorie) => (
                                    <li className='select-content__item' key={categorie}
                                        onClick={e => { setSelectedItem(e.target.textContent) }}
                                    >
                                        {categorie}
                                    </li>
                                ))
                            }

                        </ul>
                    ) : null
            }
        </div>
    );
};

export default Select;