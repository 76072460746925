import React from 'react';

const AdminCollection = () => {
    return (
        <div className='admin-collection'>
            categories
        </div>
    );
};

export default AdminCollection;