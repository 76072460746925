import { createSlice } from "@reduxjs/toolkit";


export const slice = createSlice({
    name: 'cart',
    initialState: {
        cartItems: [],
        cartTotalQuantity: 0,
        cartTotalAmount: 0
    },
    reducers: {
        addToCart: (state, action) => {

            const cartItemIndex = state.cartItems.findIndex(
                (item) => item._id === action.payload?._id
            )

            cartItemIndex >= 0 ? (
                state.cartItems[cartItemIndex].quantity += 1
            ) : (
                state.cartItems.push({ ...action.payload })
            )
        },

        increment: (state, action) => {

            const cartItemIndex = state.cartItems.findIndex(
                (item) => item?._id === action.payload?._id
            )
            if (state.cartItems[cartItemIndex].quantity) {
                state.cartItems[cartItemIndex].quantity += 1;
            }

        },

        decrement: (state, action) => {

            const cartItemIndex = state.cartItems.findIndex(
                (item) => item?._id === action.payload?._id
            )

            if (state.cartItems[cartItemIndex].quantity > 1) {
                state.cartItems[cartItemIndex].quantity -= 1
            }
        },
        removeItemCart: (state, action) => {
            state.cartItems = state.cartItems.filter(
                (item) => item._id !== action.payload._id
            )
        }
    }
})

export default slice.reducer;
export const { addToCart, increment, decrement, removeItemCart } = slice.actions;
