import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../services/routes';

const RegisterForm = () => {

    const [registerValeues, setRegisterValues] = useState({
        lastName: "",
        firstName: "",
        email: "",
        password: "",
        tel: "",
        address: ""
    })

    const navigate = useNavigate()

    let mailRegex = /^[a-z0-9.-_]+[@]{1}[a-z.-_]+[.]{1}[a-z]{2,8}$/

    const handleRegister = async (e) => {
        e.preventDefault()

        if (mailRegex.test(registerValeues.email.trim()) === false) {
            console.log("vueillez entrer une addresse mail valide")
            return
        }


        try {
            const response = await register(registerValeues)
            if (response.statusText) {
                navigate("/login")
                console.log(response.data?.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <section className='main-wrapper'>
            <div className='register' style={{ margin: "1em auto 5em" }}>
                <h1 className='register__title'> Créer un compte</h1>
                <form className='register__form' onSubmit={e => handleRegister(e)}>
                    <div className="register__form--inputgroup">
                        <input
                            type="text"
                            id="lastName"
                            placeholder=' '
                            autoComplete="off"
                            autoCorrect='off'
                            onChange={(e) => { setRegisterValues({ ...registerValeues, [e.target.id]: e.target.value }) }}
                        />
                        <label htmlFor="lastName">Nom *</label>
                    </div>
                    <div className="register__form--inputgroup">
                        <input
                            type="text"
                            id="firstName"
                            placeholder=' '
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={(e) => { setRegisterValues({ ...registerValeues, [e.target.id]: e.target.value }) }}
                        />
                        <label htmlFor="firstName">Prénom *</label>
                    </div>
                    <div className="register__form--inputgroup">
                        <input
                            type="text"
                            id="email"
                            placeholder=' '
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={(e) => { setRegisterValues({ ...registerValeues, [e.target.id]: e.target.value }) }}
                        />
                        <label htmlFor="email">Email *</label>
                    </div>
                    <div className="register__form--inputgroup">
                        <input
                            type="password"
                            id="password"
                            placeholder=' '
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={(e) => { setRegisterValues({ ...registerValeues, [e.target.id]: e.target.value }) }}
                        />
                        <label htmlFor="password">Mot de passe *</label>
                    </div>
                    <div className="register__form--inputgroup">
                        <input
                            type="text"
                            id="tel"
                            placeholder=' '
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={(e) => { setRegisterValues({ ...registerValeues, [e.target.id]: e.target.value }) }}
                        />
                        <label htmlFor="tel">Téléphone *</label>
                    </div>
                    <div className="register__form--inputgroup">
                        <input
                            type="text"
                            id="address"
                            placeholder=' '
                            autoComplete="off"
                            autoCorrect="off"
                            onChange={(e) => { setRegisterValues({ ...registerValeues, [e.target.id]: e.target.value }) }}
                        />
                        <label htmlFor="address">Adresse</label>
                    </div>

                    <input type="submit" value="Créer" className='register__form--valid' />

                </form>
            </div>
        </section>
    );
};

export default RegisterForm;