import React from 'react';
import bannerImage from '../assets/images/boucherie__banner.jpg';
const Description = () => {
    return (
        <article className='article'>
            <h2 className='article__title'>Boucherie Debouche  frères</h2>
            <p className='article__welcom'>
                Bienvenue, à la Boucherie Debouche Frères,
                <br />
                Si vous avez des question ou une demande, n'hésitez pas de nous contacter par téléphone : <em>03 84 44 31 69</em>.
                <br /> <br />
                Nous vous remercions de votre compréhension et espérons que nos services vous apporteront entière satisfaction.
            </p>
            <p>

            </p>
            <h4 className='article__title2'>Les mots du Fondateur</h4>
            <div className='article__content'>
                <img src={bannerImage} alt="boucherie description" className='article__content--picture' />
            </div>
            <div className='article__description'>
                <p>La Boucherie DEBOUCHE FRERES vous propose son savoir-faire et son expertise dans la maturation de la viande. </p>
                <br />
                <p>Nous vous offrons un large choix d'offres: pour les <strong>Repas</strong> en famille, <strong>Barbecue</strong> du à l'arrivée des beaux jours, <strong>brochettes et grillade</strong>  pour l'été, <strong>Agneau</strong>à Pâques, <strong>Volaille et gibier</strong>  pour Noël etc, la meilleure viande au meilleur prix. </p>
                <br />
                <p>Que ce soit du Boeuf, de l'agneau, de la volaille, ou de l'artisanale,... on saura toujours vous regaler.</p>
            </div>
        </article>
    );
};

export default Description;