import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import React from 'react';
import { Link } from 'react-router-dom';



const Footer = () => {

    const date = new Date().getFullYear()


    return (
        <footer className='footer'>
            <ul className='footer__wrapper'>
                <li className='footer__wrapper--item'> <Link to='/about'>A propos de nous</Link></li>
                <li className='footer__wrapper--item'> <Link to='/sale'>Conditions générales de ventes</Link></li>
                <li className='footer__wrapper--item'> <Link to='/privacy'> Données et confidentielles</Link></li>
                <li className='footer__wrapper--item'> <Link to='/contact'> Nous contacter</Link></li>
            </ul>
            <p className='footer__wrapper--copyright'>
                <CopyrightOutlinedIcon style={{ fontSize: '0.9em' }} /> {date} Boucherie Debouche. Tous droits reservés !
            </p>
        </footer>
    );
};

export default Footer;