import React from 'react';

const AdminCustommers = () => {
    return (
        <div className='admin-customers'>
            Les clients
        </div>
    );
};

export default AdminCustommers;