import React from 'react';
import facade from '../assets/images/facade_boucherie.JPG';
import boucherie from '../assets/images/slider_image1.jpg';
import charcuterie from '../assets/images/slider_image5.jpg';
import traiteur from '../assets/images/traiteur.jpg';

const AboutContent = () => {
    return (
        <>
            <section className='about'>
                <h1 className='about__title'>A propos de nous</h1>
                <div className='about__image-wrapper'>
                    <img src={facade} alt="façade boucherie" />
                </div>
                <p className='about__introduction'>
                    La Boucherie <em>DEBOUCHE FRERES</em> vous propose son savoir-faire dans la maturation de la viande. Que ce soit du Boeuf, de l'agneau ou de la volaille..., la boucherie saura toujours vous regaler.
                    Fondée en 2008, elle exerce principalement son activité sur 4 domaines: <em>BOUCHERIE</em>, <em>CHARCUTERIE</em>, <em>TRAITEUR</em> et de la <em>ROTISSERIE</em>. <br /> <br />
                    Nous vous offrons un large choix d'offres: pour les  <strong>Repas</strong>  en famille, du <strong>Barbecue</strong>  à l'arrivée des beaux jours,  < strong> brochettes et grillade </strong>pour l'été, &nbsp;
                    <strong>agneau</strong> à Pâques,  <strong> Volaille et gibier</strong> pour Noël etc, la meilleure viande au meilleur prix.
                </p>
            </section>
            <section className='about-activity'>
                <div className='about-activity__imagewrapper'>
                    <img src={boucherie} alt="boucherie" />
                    <h2 className='about-activity__imagewrapper--title'>Boucherie</h2>
                </div>
                <div className='about-activity__description'>
                    <p>
                        La boucherie <span>Debouche</span> vous propose un large choix de viandes, telles que du bœuf, du porc, de l'agneau, du poulet de la volaille et parfois du gibier.
                        <br /> <br />
                        Notre équipe est composé de professionnels qualifiés qui preparent, decoupent  la viande avec des differentes coupes(comme les steaks, les côtelettes, les rôtis, etc.) et la viande pour la vente.
                        <br /> <br />
                        Nous vous proposons également des conseils sur le choix des morceaux, des recettes et des methodes de cuisson adaptées. La viande est vendue fraîche et peut être personnalisée selon les préferences du client.
                    </p>
                </div>

                <div className='about-activity__description'>
                    <p>
                        Les charcutiers de la Boucherie Debouche, ont l'art de preparer des produits de charcuterie en utilisant diverses techniques comme la salaison, le fumage, la cuisson, la fermentation et le sechage pour créer une grande varietées de produits telles que:
                    </p>
                    <ul className='cuts'>
                        <li className='cuts__wrapper'><span className='cuts__wrapper--item'>Jambons : </span>cuits (jambon blanc) ou crus (jambon sec).</li>
                        <li className='cuts__wrapper'><span className='cuts__wrapper--item'>Saucisses et saucissons : </span> saucisses fraîches (diverses sortes de chipolatas, merguez) et saucissons secs.</li>
                        <li className='cuts__wrapper'><span className='cuts__wrapper--item'> Pâtés et terrines</span> :  mélanges de viande et d'épices cuits dans une pâte ou moulés.</li>
                        <li className='cuts__wrapper'><span className='cuts__wrapper--item'> Rillettes</span> : viande de porc ou de volaille cuite lentement dans sa graisse jusqu'à ce qu'elle se défasse en fibres.</li>
                        <li className='cuts__wrapper'><span className='cuts__wrapper--item'> Boudins </span> : boudin noir ou boudin blanc.</li>
                        <li className='cuts__wrapper'><span className='cuts__wrapper--item'> Andouilles et andouillettes </span> :  produits à base de tripes et d'autres abats.</li>
                    </ul>
                </div>
                <div className='about-activity__imagewrapper'>
                    <img src={charcuterie} alt="charcuterie" />
                    <h2 className='about-activity__imagewrapper--title'>Charcuterie</h2>
                </div>


                <div className='about-activity__imagewrapper'>
                    <img src={traiteur} alt="traiteur" />
                    <h2 className='about-activity__imagewrapper--title'>Traiteur</h2>
                </div>
                <div className='about-activity__description'>
                    <p>
                        Notre service traiteur offre un service culinaire sur mesure adapté aux besoins de chaque événement. Il est spécialisé dans la préparation, la présentation, et la livraison de repas pour des événements tels que des mariages, des réceptions, des fêtes d'entreprise, des anniversaires, des séminaires, et bien d'autres occasions. <br /> <br />
                    </p>

                    <p>  La boucherie debouche cuisine, divers plats préparés à la commande ou present chaque semaine en vitrine de votre magasin</p>
                </div>


            </section>
        </>

    );
};

export default AboutContent;