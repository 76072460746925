import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import ProductInfo from '../components/ProductInfo';
import useGetAllProducts from '../customhook/useGetAllProducts';

const Product = () => {
    const params = useParams()
    const { products } = useGetAllProducts()


    const product = products?.find((product) => product._id === params.id)

    return (
        <>
            <Header />
            <ProductInfo product={product} />
        </>
    );
};

export default Product;