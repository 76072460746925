import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cart from './Cart';

const CartList = () => {

    const cartsList = useSelector((state) => (state?.cartsList?.cartItems))
    const cartItemsLength = useSelector((state) => (state?.cartsList?.cartItems)).length

    const navigate = useNavigate()

    // console.log(cartsList)

    // Total de la somme à payer
    const cartTotalAmount = [...cartsList]?.reduce(
        (acc, cartItem) => acc + (cartItem?.price * cartItem?.quantity), 0
    )



    return (
        <>
            {
                cartItemsLength > 0 ? (
                    <section className='cart'>
                        <aside style={{ maxWidth: '760px' }}>
                            <h2 className='cart__title'>Produits dans votre panier</h2>
                            {[...cartsList]
                                ?.map((cart, index) => (
                                    <Cart key={`${index}--cart`} cart={cart} />
                                ))
                            }
                        </aside>
                        <aside className='cart-total__wrapper'>
                            <h2 className='cart-total__wrapper--total'>Total commande</h2>
                            {/* <p className='car'>Nombre de produits: 7</p> */}
                            <ul className='cart-total__wrapper--checkout'>
                                <li>TOTAL TTC: </li>
                                <li>{`${cartTotalAmount?.toFixed(2)} €`}</li>
                            </ul>
                            <div className='cart-total__wrapper--process'>
                                <button className='back' onClick={() => { navigate('/') }}>Continuer vos achats</button>
                                <button className='shop'>Passer la commande</button>
                            </div>
                        </aside>

                    </section>
                ) : (
                    <section className='cart' style={{ flexDirection: "column", margin: '2em' }}>
                        <h2 className='cart__title'> Vous n'avez encore rien ajouté dans votre panier</h2>
                        <p className='cart__back' onClick={() => { navigate('/') }} style={{ alignSelf: 'center' }}>
                            <KeyboardBackspaceIcon />
                            <span>Revenir à la liste des produits</span>
                        </p>
                    </section>
                )
            }
        </>
    );
};

export default CartList;