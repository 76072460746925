import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddProduct from '../../components/AddProduct';
import Card from '../../components/Card';
import useGetAllProducts from '../../customhook/useGetAllProducts';
import { getActionType } from '../../redux/slicers/productsSlice';


const AdminProducts = () => {

    const { products } = useGetAllProducts()
    const dispatch = useDispatch()


    let action = useSelector((state => state?.products?.actionType))
    let isOpen = useSelector((state => state?.products?.isOpen))
    console.log(isOpen)
    console.log("actionType", action)
    return (
        <>{
            isOpen ? (
                <AddProduct actionType={action} />
            )
                : (
                    <section className='admin-products'>
                        <div style={{
                            position: "sticky",
                            top: "0",
                            backgroundColor: "#fff",
                            zIndex: "99",
                            paddingTop: "2em"
                        }}>
                            <h1 className='admin-products__title' style={{ marginTop: "0" }}>Gestion des produits</h1>
                            <div className='admin-products__wrapper'>
                                <input type="search" placeholder='Rechercher un produit' id="" />
                                <button onClick={() => dispatch(getActionType("creating"))}>Ajouter un produit</button>
                            </div>
                        </div>
                        <div>
                            <aside className='admin-products__products'>
                                {
                                    products?.map((product) => (
                                        <Card key={product?._id} product={product} productType="admin" />
                                    ))
                                }
                            </aside>
                        </div>

                    </section>
                )
        }</>

    );
};

export default AdminProducts;