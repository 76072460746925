import React from 'react';
import { HiOutlineViewList } from 'react-icons/hi';
import { HiOutlineArchiveBox, HiOutlineUsers } from 'react-icons/hi2';
import { MdOutlineAdminPanelSettings, MdOutlineCollectionsBookmark, MdOutlineDashboard, MdOutlineLogout } from 'react-icons/md';

import { Link, NavLink } from 'react-router-dom';
import iconDebouche from '../assets/images/icon-debouche.png';


const SideBar = () => {
    return (
        <aside className='side-bar'>
            <Link to="/admin/dashbord" className='side-bar__logo'>
                <img src={iconDebouche} alt="logo" />
                <h1>Debouche Frères</h1>
            </Link>

            <nav className='side-bar'>
                <NavLink to="/admin/dashbord"
                    className={(nav) => (nav.isActive ? "side-bar__link nav-active" : "side-bar__link")}
                >
                    <MdOutlineDashboard />
                    <span className='side-bar__link--name'>Dashbord</span>
                </NavLink>

                <NavLink to="/admin/collection"
                    className={(nav) => (nav.isActive ? "side-bar__link nav-active" : "side-bar__link")}
                >
                    <MdOutlineCollectionsBookmark />
                    <span className='side-bar__link--name'>Categories</span>
                </NavLink>

                <NavLink
                    to="/admin/products"
                    className={(nav) => (nav.isActive ? "side-bar__link nav-active" : "side-bar__link")}
                >
                    <HiOutlineArchiveBox />
                    <span className='side-bar__link--name'>Produits</span>
                </NavLink>

                <NavLink to="/admin/orders"
                    className={(nav) => (nav.isActive ? "side-bar__link nav-active" : "side-bar__link")}
                >
                    <HiOutlineViewList />
                    <span className='side-bar__link--name'>Commandes</span>
                </NavLink>

                <NavLink to="/admin/customers"
                    className={(nav) => (nav.isActive ? "side-bar__link nav-active" : "side-bar__link")}
                >
                    <HiOutlineUsers />
                    <span className='side-bar__link--name'>Clients</span>
                </NavLink>

                <NavLink to="/admin/admins"
                    className={(nav) => (nav.isActive ? "side-bar__link nav-active" : "side-bar__link")}
                >
                    <MdOutlineAdminPanelSettings />
                    <span className='side-bar__link--name'>Admins</span>
                </NavLink>

                <NavLink to="/" className="side-bar__link">
                    <MdOutlineLogout />
                    <span className='side-bar__link--logout'>Déconnexion</span>
                </NavLink>

            </nav>
        </aside>
    );
};

export default SideBar;