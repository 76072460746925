import React from 'react';

const Privacies = () => {
    return (
        <section className='privacy'>
            <h1 className='privacy__title'>Données et confidentilaités</h1>
            <h2 className='privacy__subtitle'>Legales</h2>
            <p className='privacy__parag'>
                Conformément aux dispositions de la Loi L.C.E.N ( Loi n°2004-575 du 21 juin 2004  pour la Confiance dans l’économie numérique), il est porté à la connaissance des utilisateurs et visiteurs du site Boucherie-debouche les présentes mentions légales.
                Le site Boucherie Debouche est accessible à l’adresse suivante : www.boucheriedebouche.fr (ci-après « le Site »). L’accès et l’utilisation du Site sont soumis aux présentes  Mentions légales et impliquent l’acceptation intégrale et sans réserve de l’internaute de toutes les dispositions des ces Mentions Légales.
            </p>
            <br />
            <p className='privacy__parag'>
                Le site Boucherie-debouche est la propriété de l'entreprise Debouche Freres Sarl à Pont De Poitte (Jura), domiciliée au 50 Grand Rue Rue 39130 Pont De Poitte, est immatriculée sous le SIREN 502735020 et le numéro de TVA intra-communautaire. <br />
            </p>
            <h2 className='privacy__subtitle'>President Directeur Fondateur</h2>
            <p className='privacy__parag'> Le Directeur de L'entreprise Debouche Fères  est Denis Debouche <br /> Adresse email de contact: à Definir</p>

            <h2 className='privacy__subtitle'>Editeur  du site</h2>
            <p className='privacy__parag'> Baïlo SOW</p>

            <h2 className='privacy__subtitle'>Hebergeur du site</h2>
            <p className='privacy__parag'> Le site est hebergé par : HEROKU CLOUD APPLICATION PLATFORM et le nom de domaine par IONOS</p>

            <h2 className='privacy__subtitle'>Accessibilité</h2>
            <p className='privacy__parag'>
                Le Site est par principe accessible aux Utilisateurs 24/24h et 7/7j, sauf interruption, programmée ou non, pour des besoins de maintenance ou en cas d'évènements inattendus. <br /><br />
                En cas d’impossibilité d’accès au Site, celui-ci s’engage à faire son maximum afin d’en rétablir l’accès. Le Site ou son propriétaire ne saurait être tenu pour responsable de tout dommage, quelle qu’en soit la nature, résultant de son indisponibilité.
            </p>
            <h2 className='privacy__subtitle'>Données</h2>
            <p className='privacy__parag'>
                Nous utilisons les informations que vous nous fournissez spéciquement pour vous indentifier afin de traiter les transactions, effectuer vos paiements en ligne. Afin de pouvoir remettre la commande  à  la bonne personne.
            </p>

            <h2 className='privacy__subtitle'>Confidentialités</h2>
            <p className='privacy__parag'>
                nous mettons en œuvre diverses mesures de sécurité, notamment en proposant l'utilisation d'un serveur sécurisé. Toutes les informations sensibles/de crédit fournies sont transmises via la technologie Secure Socket Layer (SSL), puis cryptées dans notre base de données.
                Après une transaction, vos informations privées (cartes de crédit) ne seront pas stockées sur nos serveurs. <br /><br />
                Cela dit, Vous êtes responsable la sécurité de votre nom d'utilisateur et de votre mot de passe sur le site boucherie debouche.
                Nous vous recommandons de choisir un mot de passe fort et veuillez ne pas utiliser les mêmes informations de connexion (e-mail et mot de passe) sur plusieurs sites Web.
            </p>
            <h2 className='privacy__subtitle'> Vos droits </h2>
            <p className='privacy__parag'>
                Vous disposez d'un droit d'accès, de rectification ou de suppression des informations personnelles que nous collectons. Vous avez également le droit de restreindre ou de vous opposer, à tout moment, au traitement ultérieur de vos informations personnelles. <br />
                Pour protéger la confidentialité et la sécurité de vos informations personnelles, nous pouvons vous demander des informations pour nous permettre de confirmer votre identité et votre droit d'accéder à ces informations.
            </p>

            <h2 className='privacy__subtitle privacy__subtitle--law '>LOI APPLICABLE ET JURIDICTION</h2>
            <p className='privacy__parag'>Les présentes Mentions Légales sont régies par la loi française. En cas de différend et à défaut d’accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de compétence en vigueur.</p>
        </section>
    );
};

export default Privacies;