import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: "create-update",
    initialState: {
        actionType: "",
        isOpen: false,
        productId: "",
        isSearch: false
    },
    reducers: {
        getActionType: (state, action) => {
            state.actionType = action.payload
            state.isOpen = !state.isOpen

        },
        getProductId: (state, action) => {
            state.productId = action.payload
        },
        getIsSearching: (state, action) => {
            state.isSearch = action.payload
        }
    }
})

export default productSlice.reducer;
export const { getActionType, getProductId, getIsSearching } = productSlice.actions;