import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { login } from '../services/routes';

const LoginForm = () => {
    const [values, setValues] = useState({ email: "", password: "" })
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()
    let mailRegex = /^[a-z0-9.-_]+[@]{1}[a-z.-_]+[.]{1}[a-z]{2,8}$/


    useEffect(() => {
        setErrorMessage("")
    }, [values.email, values.password])

    const handleLogin = async (e) => {
        e.preventDefault()

        if (mailRegex.test(values?.email?.trim()) === false) {
            setErrorMessage("Veuillez entrer une adresse mail Valide")
            return
        }
        if (values?.password?.trim().length === 0) {
            setErrorMessage("le champs mot de passe  est requis")
            return
        }

        try {
            const response = await login(values)
            if (response.statusText) {
                localStorage.setItem('token', JSON.stringify(response.data.token))
                navigate('/')
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                setErrorMessage("Votre email ou mot de passe ne correspondent pas")
            } else {
                setErrorMessage("Erreur serveur")
            }
        }
    }

    return (
        <section className='main-wrapper'>
            <div className='register'>
                <PersonOutlineOutlinedIcon />
                <h1 className='register__title'>Connexion</h1>
                <form className='register__form' onSubmit={(e) => { handleLogin(e) }}>
                    <div className="register__form--inputgroup">
                        <input
                            type="text"
                            id="email"
                            placeholder=' '
                            autoComplete='off'
                            autoCorrect='off'
                            onChange={(e) => { setValues({ ...values, [e.target.id]: e.target.value }) }}
                        />
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="register__form--inputgroup">
                        <input
                            type="password"
                            id="password"
                            placeholder=' '
                            autoComplete='off'
                            autoCorrect='off'
                            onChange={(e) => { setValues({ ...values, [e.target.id]: e.target.value }) }}
                        />
                        <label htmlFor="password">Mot de passe</label>
                        <div className='link-password'>
                            <Link to={""}> Mot de passe oublié ?</Link>
                        </div>
                    </div>

                    {errorMessage && <p className='register__form--error'>{errorMessage}</p>}

                    <input type="submit" value="Se connecter" className='register__form--valid' />

                    <p className='register__form--link'>
                        <NavLink to="/register">Créer un compte</NavLink>
                    </p>
                </form>
            </div>
        </section>
    );
};

export default LoginForm;