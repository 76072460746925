import CreditCardIcon from '@mui/icons-material/CreditCard';
import React from 'react';
import emporter from '../assets/images/emporter.png';

const TakeAway = () => {
    return (
        <div className='takeaway'>
            <div className='takeaway__wrapper'>
                <img src={emporter} alt="emporter" />
                <p className='takeaway__text'>Emporter</p>
            </div>
            <div className='takeaway__wrapper'>
                {/* <img src={livraison} alt="Livraison" /> */}
                <CreditCardIcon style={{ height: '4.2em', width: '4em' }} />
                <p className='takeaway__text'>Paiement sécurisé</p>
            </div>
        </div>
    );
};

export default TakeAway;