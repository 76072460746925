import { useSelect } from '@mui/base';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import React from 'react';
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useGetAllProducts from '../customhook/useGetAllProducts';
import { addToCart } from '../redux/slicers/cartSlice';
import { getActionType, getIsSearching, getProductId } from '../redux/slicers/productsSlice';
import { deleteProduct } from '../services/routes';




const Card = ({ product, productType }) => {
    const dispatch = useDispatch()
    const isSearch = useSelect((state) => state?.products?.isSearch)
    const isAdminComponent = productType ? "admin" : ""
    const { mutate } = useGetAllProducts()

    // function to add product in cartShop
    const handleAddTocart = () => {
        let quantity = 1;
        dispatch(addToCart({ ...product, quantity }))
    }

    const handleEdit = () => {
        dispatch(getActionType("editing"))
        dispatch(getProductId(product?._id))
    }

    const handleDelete = async () => {
        const response = await deleteProduct(product._id)
        if (response.status === 200) {
            mutate()
        }
    }

    const host = window.location.host
    const protocol = window.location.protocol




    return (
        <aside className='card'>

            <NavLink to={`/${product?._id}`} onClick={() => { isSearch && dispatch(getIsSearching(false)) }}>
                <div className='card__first-part'>
                    <img src={`${protocol}//${host}/images/${product?.image}`} alt={product?.title} className='card__first-part--picture' />
                </div>
            </NavLink>

            <h4 className='card__title'> {product?.title} </h4>
            <p className='card__category'>{product?.categorie}</p>
            <ul className='card__second-part'>
                <li className='card__second-part--price'> {`${product?.price?.toFixed(2)} € / KG`} </li>
                {isAdminComponent ? (
                    <li className='card__second-part--update-delete'>
                        <HiOutlinePencilAlt className='modify' onClick={handleEdit} />
                        <HiOutlineTrash className='delete' onClick={() => {
                            if (window.confirm(`voulez vous supprimez le produit ${product?.title} ?`)) {
                                handleDelete()
                            }
                        }} />
                    </li>

                ) : (

                    <li className='card__second-part--cartshop'
                        onClick={() => handleAddTocart()}
                    >
                        <ShoppingCartOutlinedIcon className='icon' />
                    </li>
                )}

            </ul>
        </aside>
    );
};

export default Card;