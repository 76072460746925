import React from 'react';
import { useDispatch } from 'react-redux';
import { decrement, increment } from '../redux/slicers/cartSlice';


const SelectQuantity = ({ quantity, width, align, product }) => {
    // const quantity = useSelector(state => state?.cartsList?.quantity);
    const dispatch = useDispatch()

    // console.log(quantity);
    // console.log(product)


    return (
        <div className="prd-info__count" style={{ width: width, alignSelf: align }}>
            <button className="prd-info__count--btnleft" onClick={() => { dispatch(decrement(product)) }}> - </button>
            <p className="prd-info__count--number">{quantity}</p>
            <button className="prd-info__count--btnright" onClick={() => { dispatch(increment(product)) }}> + </button>
        </div>
    );
};

export default SelectQuantity;
