import useSWR from 'swr';
import useAxiosPrivate from "./useAxiosPrivate";



const useUserProfile = () => {
    const userToken = JSON.parse(localStorage.getItem('token'))
    const token = userToken
    const axiosPrivate = useAxiosPrivate()

    const fetcherWithToken = (url, token) => {
        if (token) {
            return axiosPrivate.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => res.data)
        }
        return
    }

    const { data, error, isLoading } = useSWR(["/profile", token], ([url, token]) => fetcherWithToken(url, token), {
        refreshInterval: 5000,
    })


    return {
        user: data,
        isLoading,
        isError: error,
    }
}

export default useUserProfile;