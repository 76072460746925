import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Orders = () => {
    return (
        <>
            <Header />
            <div> Pas de commandes passés</div>
            <Footer />
        </>
    );
};

export default Orders;