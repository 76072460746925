import { configureStore } from "@reduxjs/toolkit"
import authReducer from './slicers/authSlice'
import cartsReducer from './slicers/cartSlice'
import productsSlice from "./slicers/productsSlice"




export const store = configureStore({
    reducer: {
        cartsList: cartsReducer,
        auth: authReducer,
        products: productsSlice
    }
})