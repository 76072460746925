import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Card from '../components/Card';
import useGetAllProducts from '../customhook/useGetAllProducts';
import { getIsSearching } from '../redux/slicers/productsSlice';


const Search = () => {
    const dispatch = useDispatch()
    const { products } = useGetAllProducts()
    const [searchingValue, setSearchingValue] = useState("")
    const searchingValueLength = searchingValue?.trim().length

    const isEmptyValueSearch = searchingValueLength !== 0 ? true : false

    return (
        <div className='modal' style={{ backgroundColor: isEmptyValueSearch ? "#fff" : "" }}>
            <div className='modal__search'>
                <div className='modal__search--content'>
                    <SearchOutlinedIcon className='icon-search' />
                    <label htmlFor="search"></label>
                    <input
                        type="search"
                        placeholder='Rechercher un produit...'
                        className='modal__search--input'
                        onChange={(e) => setSearchingValue(e.target.value)}
                        id="search"
                    />
                    <button className='close-search' onClick={() => { dispatch(getIsSearching(false)) }}>x</button>
                </div>
                <section className="products" style={{ backgroundColor: "#fff" }}>
                    {isEmptyValueSearch ? (
                        products?.filter(
                            (product) => (product?.title?.toLowerCase().includes(searchingValue?.toLowerCase())))
                            ?.map((product) => (
                                <Card key={product?._id} product={product} />))
                    ) : null

                    }
                </section>
            </div>
            <div className='modal__content'></div>
        </div>
    );
};

export default Search;