import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Privacies from '../components/Privacies';

const Privacy = () => {
    return (
        <>
            <Header />
            <Privacies />
            <Footer />
        </>
    );
};

export default Privacy;