import React, { useState } from 'react';
import useUserProfile from '../customhook/useUserProfile';
import LabelInput from './LabelInput';

const EditUser = ({ setEditing }) => {

    const [values, setValues] = useState({
        lastName: "",
        firstName: "",
        email: "",
        tel: "",
        address: ""
    })

    const { user } = useUserProfile()

    const handleEdit = () => {

    }
    const handleCancel = () => {
        setEditing(false)
    }
    return (
        <section className='profile-edit' style={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
            <h1 className='profile-edit__title'>Modifier les informations</h1>
            <LabelInput name="lastName" setValues={setValues} values={values} currentValue={user?.firstName} />
            <LabelInput name="firstName" setValues={setValues} values={values} currentValue={user?.lastName} />
            <LabelInput name="email" setValues={setValues} values={values} currentValue={user?.email} />
            <LabelInput name="tel" setValues={setValues} values={values} currentValue={user?.tel} />
            <LabelInput name="address" setValues={setValues} values={values} currentValue={user?.address} />

            <button className='profile-edit__save' onClick={() => { handleEdit() }}>Enregistrer</button>
            <button className='profile-edit__cancel' onClick={() => handleCancel()}>Annuler</button>
        </section>
    );
};

export default EditUser;