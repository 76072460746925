import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React from 'react';
import { useDispatch } from 'react-redux';
import { removeItemCart } from '../redux/slicers/cartSlice';
import SelectQuantity from './SelectQuantity';

const Cart = ({ cart }) => {
    const dispatch = useDispatch()

    const host = window.location.host
    const protocol = window.location.protocol

    return (
        <ul className='cart__shop'>
            <li className='cart__shop--picture'>
                <img src={`${protocol}//${host}/images/${cart?.image}`} alt={cart?.title} />
            </li>
            <li className='cart__shop--title'>{cart?.title}</li>
            <SelectQuantity product={cart} quantity={cart?.quantity} width="130px" align="inherit" />
            <li className='cart__shop--total'>{(cart?.price * cart?.quantity).toFixed(2)}&nbsp;€</li>
            <li className='cart__shop--delet' onClick={() => { dispatch(removeItemCart(cart)) }}>
                <DeleteForeverIcon className='cart__shop--delete' />
            </li>
        </ul>
    );
};

export default Cart;