import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetAllProducts from '../customhook/useGetAllProducts';
import { getActionType } from '../redux/slicers/productsSlice';
import { createProduct, updatProduct } from '../services/routes';
import Select from './Select';

const AddProduct = ({ actionType }) => {
    const [productsValues, setPorductsValues] = useState({
        title: "",
        image: "",
        price: 0,
        description: ""
    })



    const dispatch = useDispatch()
    const productId = useSelector((state) => state?.products?.productId)
    const { products, mutate } = useGetAllProducts()

    const action = actionType === "creating" ? true : false;

    const productModifying = products?.find((product) => product._id === productId)
    const [selectedItem, setSelectedItem] = useState(actionType === "creating" ? "Choisir une catégorie" : productModifying?.categorie)

    const formData = new FormData()
    formData.append("title", productsValues.title || productModifying?.title)
    formData.append("image", productsValues.image || productModifying?.image)
    formData.append("price", productsValues.price || productModifying?.price)
    formData.append("categorie", selectedItem || productModifying?.categorie)
    formData.append("description", productsValues.description || productModifying?.description)

    const handleCreate = async (e) => {
        e.preventDefault()
        console.log(productsValues, "formData", formData)
        try {
            const response = await createProduct(formData)
            if (response.status === 201) {
                mutate()
                dispatch(getActionType("creating"))
            }

        } catch (error) {
            console.log(error)
        }
    }


    const handleUpdate = async (e) => {
        e.preventDefault()

        try {
            const response = await updatProduct(productModifying._id, formData)
            if (response.status === 200) {
                mutate()
                dispatch(getActionType("editing"))
            }

        } catch (error) {

        }

    }

    return (
        <section className='add-product'>
            <h1 className='add-product__title'>{action ? "Ajouter un nouveau produit" : "Modifier un produit"}</h1>
            <form className='add-product__form' onSubmit={e => action ? handleCreate(e) : handleUpdate(e)} encType="multipart/form-data">
                <div className="add-product__form--inputgroup">
                    <label htmlFor="title">Titre du produit</label>
                    <input
                        type="text"
                        id="title"
                        placeholder='Titre du produit'
                        autoComplete="off"
                        autoCorrect='off'
                        onChange={(e) => { setPorductsValues({ ...productsValues, [e.target.id]: e.target.value || productModifying?.title }) }}
                        defaultValue={!action ? productModifying.title : ""}
                    />

                </div>
                <div className="add-product__form--inputgroup">
                    <label htmlFor="image">Ajoute une image</label>
                    <input
                        type="file"
                        id="image"
                        name='image'
                        placeholder=' '
                        autoComplete="off"
                        autoCorrect="off"
                        onChange={(e) => { setPorductsValues({ ...productsValues, [e.target.id]: e.target.files[0] }) }}
                        defaultValue=""
                    />
                </div>
                <div className="add-product__form--inputgroup">
                    <label htmlFor="price">Prix</label>
                    <input
                        type='text'
                        id="price"
                        placeholder='le prix'
                        autoComplete="off"
                        autoCorrect="off"
                        onChange={(e) => { setPorductsValues({ ...productsValues, [e.target.id]: e.target.value }) }}
                        defaultValue={!action ? productModifying.price : ""}
                    />
                </div>
                <Select selectedItem={selectedItem} setSelectedItem={setSelectedItem} action={action} prevValue={productModifying?.categorie} />
                <div className="add-product__form--inputgroup">
                    <label htmlFor="description"> Description</label>
                    <textarea
                        id="description"
                        placeholder='Descritption'
                        autoComplete="off"
                        autoCorrect="off"
                        onChange={(e) => { setPorductsValues({ ...productsValues, [e.target.id]: e.target.value }) }}
                        rows="5"
                        defaultValue={!action ? productModifying.description : ""}
                    />
                </div>
                <div className='add-product__form--btn'>
                    <button type='submit' >{action ? "Valider" : "Enregister"}</button>
                    <button onClick={() => { dispatch(getActionType(action ? "creating" : "editing")) }} >Annuler</button>
                </div>
            </form>
        </section>
    );
};

export default AddProduct;