import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
    return (
        <section className='sale'>
            <h1 className='sale__title' style={{ textAlign: 'center' }}>Conditions générales de ventes</h1>
            <h2 className='sale__subtitle'>Produits</h2>
            <p>
                L’ensemble des produits proposés par DEBOUCHE FRERES peuvent être commandés à tout moment pendant les horaires d'ouverture.
                Il est de la responsabilité du client de vérifier l’exactitude des informations fournies concernant sa commande. Le Client est conscient que le contenu des menus (visuels inclus) peuvent parfois être modifiés sur l'initiative de notre Entreprise.
                Il est de la responsabilité du client de vérifier l’exactitude des informations fournis concernant sa commande.
            </p>
            <p> Les commandes à <em>EMPORTER</em> passées en ligne sont à venir recuperer au magasin.</p>
            <h2 className='sale__subtitle'>Modification/Annulation</h2>
            <p> Aucune modification ou annulation ne peut être enregistrée sur Internet. Afin d’annuler, ou modifier une commande, nous vous invitons à nous contacter par téléphone au 03 84 44 31 69 ou de nous confirmer par mail l’annulation de cette dernière dans un délai de 10 minutes apès la validation.</p>
            <h2 className='sale__subtitle'>Conditions particulières</h2>
            <p> Nos offres peuvent être modifiées à tout moment. Les prix indiquées sont en euros TTC. Nous nous engageons à preparer votre commande dans le delai imparti selon les commandes.
                ce delai peut être prolongé par temps d'afflucence.
            </p>
            <h2 className='sale__subtitle'>Informations personnelles</h2>
            <p>Veiullez à l’exactitude et à la précision de vos coordonnées  de facturation ainsi qu' à l'exactitude du contenu de votre commande. Nous vous invitons donc à contrôler votre avant de valider le paiement.</p>
            <h2 className='sale__subtitle'>Modalité de paiement</h2>
            <p>Toute commande doit être réglée à réception. Toute commande passée par internet doit être réglée en ligne ou à la réception.Les paiements par carte bancaire sont débités au moment de la validation de la commande. Les paiements acceptés sont: CB, TICKETS RESTAURANT et ESPECES</p>
            <h2 className='sale__subtitle'>Loi Applicable</h2>
            <p>Les conditions générales de vente sont régies par la Loi française. Pour tous litiges avec un client, le tribunal de commerce sera compétent conformément aux règles en vigeur.</p>
            <h2 className='sale__subtitle'>Conseils</h2>
            <p>Pour votre santeé, sachez consommez avec modération, pratiquez une activité physique regulière. <Link to="www.mangerbouger.fr"> www.mangerbouger.fr</Link>
                . Ne pas jeter sur la voie publique les produits et les catalogues</p>
        </section>
    );
};

export default TermsAndConditions;