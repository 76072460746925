import React from 'react';
import banner_image from '../assets/images/slider_image1.jpg';

const Banner = ({ title }) => {


    // const sliderImages = [
    //     {
    //         image: banner_image,
    //         title: "boucherie"
    //     },
    //     {
    //         image: require('../assets/images/slider_image2.jpg'),
    //         title: "charcuterie"
    //     },
    //     {
    //         image: require('../assets/images/slider_image3.jpg'),
    //         title: "Traiteur"
    //     },
    //     {
    //         image: require('../assets/images/slider_image5.jpg'),
    //         title: "boucherie"
    //     }
    // ]   


    return (
        <section className='banner'>
            <div className='banner__wrapper'>
                <img src={banner_image} alt="Boucherie Debouche" className='banner__wrapper--picture' />
            </div>
            <h1 className='banner__title'>{title}</h1>
        </section>
    );
};

export default Banner;