import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Badge } from '@mui/material';
import React from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo-debouche1.png';
import useUserProfile from '../customhook/useUserProfile';
import { getIsSearching } from '../redux/slicers/productsSlice';
import DropDownMenu from './DropDownMenu';
import Search from './Search';



const Header = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cartTotalQuantity = useSelector(state => state?.cartsList?.cartItems)
    const isSearch = useSelector((state) => state?.products?.isSearch)

    const { user, isError } = useUserProfile()

    const body = document.querySelector('body')
    isSearch ? body.style.overflowY = "hidden" : body.style.overflowY = "visible"


    // window.addEventListener('scroll', () => {
    //     const header = document.querySelector('.header')
    //     if (document.documentElement.scrollTop > 30) {
    //         header.classList.add("sticky")
    //     } else {
    //         header.classList.remove("sticky")
    //     }
    // })     
    return (
        <>
            <header className='header'>
                <div className='header__logo'>
                    <NavLink to='/'>
                        <img src={logo} alt="logo boucherie debouche" className='header__logo--debouche' />
                    </NavLink>
                </div>
                <ul className='header__nav'>
                    {
                        user === undefined || isError ? (
                            <Link to="/login">
                                <li className='header__nav--item'>
                                    <AccountCircleIcon className='icon' />
                                </li>
                            </Link>
                        ) : (
                            <li className='header__nav--item profile'>
                                <DropDownMenu user={user} />
                            </li>
                        )
                    }

                    <Link to={''} onClick={() => { dispatch(getIsSearching(true)) }}>
                        <li className='header__nav--item' onClick={() => navigate('')}>
                            {/* <input type="search" placeholder='Recherche' className='search' /> */}
                            <SearchOutlinedIcon className='icon' />
                        </li>
                    </Link>

                    <Link to={"/cart"}>
                        <li className='header__nav--item'>
                            <Badge badgeContent={cartTotalQuantity?.length} color="primary">
                                <ShoppingCartOutlinedIcon className='icon' />
                            </Badge>
                        </li>
                    </Link>
                </ul>
            </header>
            {isSearch && createPortal(<Search />, document.body)}
        </>

    );
};

export default Header;