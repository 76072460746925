import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LoginForm from '../components/LoginForm';
import TakeAway from '../components/TakeAway';

const Login = () => {
    return (
        <>
            <Header />
            <LoginForm />
            <TakeAway />
            <Footer />
        </>
    );
};

export default Login;