import React from 'react';
import cb from '../assets/images/cb.png';
import maestro from '../assets/images/maestro.png';
import mastercard from '../assets/images/mastercard.png';
import sans_contact from '../assets/images/sans_contact.png';

const ContactAddress = () => {
    return (
        <section className='contact'>
            <iframe
                title='boucherie debouche frères address'
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10968.26916314577!2d5.6966498!3d46.5859071!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478cddb8f359ae83%3A0x6a369399d48b8a40!2sDebouche%20Fr%C3%A8res!5e0!3m2!1sfr!2sfr!4v1687938194493!5m2!1sfr!2sfr"
                width="100%"
                height="450"
                style={{ border: '0px' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
            <aside className='contact__infos'>
                <h1 className='contact__infos--title'> Contact</h1>
                <h2 className='contact__infos--title2'>Debouche frères</h2>
                <address className='contact__infos--address'>
                    Adresse : 50 Grande Rue, 39130 Pont-de-Poitte
                </address>
                <ul className='contact__infos--wrapper'>
                    <li className='item'> Téléphone :  03 84 44 31 69</li>
                    <li className='item'>Modes de paiement acceptés : CB, Espèces, tikets restaurant</li>
                    <li className='item'>Horaire d'ouverture : à  definir</li>
                </ul>
            </aside>
            <aside className='contact__cb'>
                <ul className='contact__cb--image'>
                    <li className='icon'><img className='icon__item' src={cb} alt="carte cb" /></li>
                    <li className='icon'><img className='icon__item' src={maestro} alt="carte maestro" /></li>
                    <li className='icon'><img className='icon__item' src={mastercard} alt=" carte mastercard" /></li>
                    <li className='icon'><img className='icon__item' src={sans_contact} alt=" sans contact" /></li>
                </ul>
            </aside>
        </section>
    );
};

export default ContactAddress;