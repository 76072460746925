import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../services/routes';

const DropDownMenu = ({ user }) => {

    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    const handleClick = () => {
        setOpen(open => !open)
    }

    const handleLogOut = () => {
        axiosPrivate.post("/logout", {}, { withCredentials: true })
        localStorage.removeItem("token")
        navigate('/')
    }


    return (
        <div className='profile'>
            <button className='profile__name' onClick={() => handleClick()}>
                {`${user?.firstName?.slice(0, 1)}${user?.lastName?.slice(0, 1)}`}
            </button>
            <ArrowDropDownIcon
                className='profile__icon'
                style={{
                    transform: open ? "rotate(180deg)" : "",
                    transition: "transform ease-in-out .2s"
                }}
            />

            {
                open ? (
                    <ul
                        className='profile__dropdown'
                        style={{ padding: open ? "" : "0", transformOrigin: open ? "top" : "" }}
                    >
                        <li className='profile__dropdown--name'>{`${user?.firstName} ${user?.lastName}`}</li>
                        <NavLink to="/profile">
                            <li className='profile__dropdown--item'>Profil</li>
                        </NavLink>

                        <NavLink to="/orders">
                            <li className='profile__dropdown--item'>commandes</li>
                        </NavLink>
                        <li className='profile__dropdown--item' onClick={() => handleLogOut()}>Deconnection</li>
                    </ul>
                ) : null
            }

        </div>
    );
};

export default DropDownMenu;