import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { addToCart } from '../redux/slicers/cartSlice';


const ProductInfo = ({ product }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [quantity, setQuantity] = useState(1)


    const handleAddToCart = () => {
        dispatch(addToCart({ ...product, quantity: quantity }))
    }

    const host = window.location.host
    const protocol = window.location.protocol

    return (
        <section className='prd-info'>
            <NavLink to='/' className='prd-info__icon'>
                <KeyboardBackspaceIcon />
            </NavLink>

            <div className='prd-info__picture'>
                <img src={`${protocol}//${host}/images/${product?.image}`} alt={product?.title} className='prd-info__picture--image' />
            </div>
            <h1 className='prd-info__title'>{product?.title}</h1>
            <p className='prd-info__description'>{product?.description}</p>
            <p className='prd-info__quantity'>Quantité</p>
            <div className="prd-info__count">
                <button className="prd-info__count--btnleft" onClick={() => { if (quantity > 1) setQuantity(quantity - 1) }}> - </button>
                <p className="prd-info__count--number">{quantity}</p>
                <button className="prd-info__count--btnright" onClick={() => { setQuantity(quantity + 1) }}> + </button>
            </div>
            <h4 className='prd-info__price'>{`${product?.price?.toFixed(2)} €`}</h4>
            <div className='prd-info__cart'
                onClick={() => { handleAddToCart(); navigate('/cart') }}
            >
                <ShoppingCartOutlinedIcon style={{ marginRight: ".3em" }} />
                <p>Ajouter au panier</p>
            </div>
        </section>
    );
};

export default ProductInfo;