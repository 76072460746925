import { axiosPrivate } from "../services/routes";

const useAxiosPrivate = () => {

    axiosPrivate.interceptors.request.use(
        (config) => {
            const token = JSON.parse(localStorage.getItem("token"))

            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosPrivate.interceptors.response.use(
        (res) => res,
        async (error) => {

            const prevRequest = error?.config;

            if (error?.response?.status === 401 && !prevRequest.sent) {
                //upadate localstorage
                localStorage.removeItem("token")
                prevRequest.sent = true

                // refresh-token
                const response = await axiosPrivate.post("/refresh-token", {}, { withCredentials: true });

                if (response.status === 200) {
                    const { token } = response.data;
                    localStorage.setItem("token", JSON.stringify(token));
                    // axiosPrivate.defaults.headers.common["Authorization"] = `Bearer ${token}`
                }

                return axiosPrivate(prevRequest);
            }

            return Promise.reject(error);
        }
    );

    return axiosPrivate;
};

export default useAxiosPrivate;
