import useSWR from 'swr'
import { axiosPrivate } from "../services/routes"


const useGetAllProducts = () => {
    const fetchProducts = async (url) => {
        return await axiosPrivate.get(url).then(res => res.data)
    }

    const { data, isLoading, error, mutate } = useSWR("/products", (url) => fetchProducts(url))

    return {
        products: data,
        isLoading,
        isError: error,
        mutate
    }
}


export default useGetAllProducts;