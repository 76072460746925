import React from 'react';

const Dashbord = () => {
    return (
        <div className='admin-dashbord'>
            Dashbord
        </div>
    );
};

export default Dashbord;